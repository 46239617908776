<template lang="pug">
.px-md-8.py-8
  v-alert.mb-4(v-if='error', color='red', dark='', dismissible='')
    span(v-if='error') {{ error }}
  .px-4.mb-8(flat color="transparent")
    h2 E-Mail bestätigen
    p.switch-auth-link
      | Um sicherzustellen, dass die E-Mail-Adresse zu Ihnen gehört, haben wir Ihnen eine Bestätigungs-Mail geschickt.
      | Klicken Sie dort auf den Link. Sollten Sie die E-Mail nicht erhalten haben, klicken Sie auf
      a(href="#" @click="resendVerification") "E-Mail erneut senden".

  .px-4.d-flex.flex-column.flex-md-row
            v-btn(depressed color="accent" @click="reloadPage") Bestätigung prüfen
            v-btn.mt-2.mt-md-0.ml-0.ml-md-2(text @click="logout") Abbrechen
</template>

<script>
import FormRules from '@/utilities/FormRules';
import Footer from '@/components/navigation/Footer.vue';
import { auth } from '@/firebase';
export default {
  components: {
    Footer,
  },
  data() {
    return {
      ...FormRules,
      valid: true,
      password: '',
      email: '',
      checkbox: false,
      lazy: false,
    };
  },
  computed: {
    error() {
      return this.$store.getters['user/error'];
    },
    loading() {
      return this.$store.getters['user/loading'];
    },
  },
  methods: {
    reloadPage() {
      const isEmailChange = this.$route.query._ec == '1';
      this.$router.push(isEmailChange ? '/dashboard?_ec=1' : '/dashboard');
      location.reload();
    },
    logout() {
      this.$store.dispatch('user/logout');
    },
    resendVerification() {
      this.$store.dispatch('user/resendVerification');
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    auth.onAuthStateChanged((authUser) => {
      if (authUser.emailVerified) {
        const isEmailChange = this.$route.query._ec == '1';
        this.$router.push(isEmailChange ? '/dashboard' : '/dashboard');
      }
    });
  },
};
</script>

<style lang="scss">
@import '@/assets/style/main';

.switch-auth-link {
  a {
    color: $accent;
  }
}
</style>
